export const containerCategoryEmptyMapping: { [key: string]: string }[] = [
  { key: '20ST', value: '20\' Standard' },
  { key: '40ST', value: '40\' Standard' },
  { key: '40HC', value: '40\' HC' },
  { key: '45', value: '45\' Standard' },
  { key: '20RF', value: '20\' Reefer' },
  { key: '40RF', value: '40\' Reefer' },
  { key: 'Flat', value: 'Flat' },
  { key: 'Special', value: 'Special' },
];

export const containerTypeCodeMapping: Record<string, string[]> = {
  "20'RF": ['20RF'],
  "20RF": ['20RF'],
  "20'ST": ['20ST'],
  "20ST": ['20ST'],
  "20'DV": ['20ST'],
  "40'RF": ['40RF'],
  "40RF": ['40RF'],
  "40'ST": ['40ST'],
  "40ST": ['40ST'],
  "40'DV": ['40ST'],
  "40'HC": ['40HC'],
  "40HC": ['40HC'],
  "45'": ['45'],
  "20' Flat": ['Flat'],
  "40' Flat": ['Flat'],
  'Flat': ['Flat'],
  "20' Tank": ['Special'],
  "40' Tank": ['Special'],
  "Special": ['Special'],
  "45'PW":	['45'],
};

export enum AppointmentStatusTypes {
  BOOKED = 'Booked',
  FULFILLED = 'Fulfilled',
  CANCELED = 'Canceled',
}
