import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { map, filter } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { format } from 'date-fns';

import { FeatureFlagService } from '@services/feature-flag.service';
import * as containerConstants from '@app/core/services/container.constants';
import { AppointmentStatusTypes } from '@app/modules/dashboard/models/container-category.mapping';
import {
  CONTAINER_LENGTH,
  CONTAINER_NUMBER,
  FLAG,
  HOLDS,
  IS_AVAILABLE_FOR_PICKUP,
  LAST_CLAIMED,
  OWNER,
  PENDING_CONTAINERS,
  TERMINAL,
  TERMINAL_NAME,
  TRIP_ID,
  UNLOAD_EVENT,
  VESSEL_FILTER,
  VESSEL_NAME,
  WORKING_VESSELS,
} from '@models/dashboard/filters.model';
import { TimezoneService } from '@app/core/services/timezone.service';
import { getContainerStatusListResults } from '@store/dashboard/dashboard.selectors';
import * as fromDashboard from '@store/dashboard/dashboard.selectors'
import { Router } from '@angular/router';
import { ApiCallService } from './api-call.service';

export const CONTAINER_LIST_SIZE = 50;

@Injectable({
  providedIn: 'root',
})
export class ContainerStatusListService {
  private sourceFieldsToSend = containerConstants.containerSourceFields;
  private fromDate = new Date(new Date(new Date().setDate(new Date().getDate() - 90))).toISOString().substr(0, 10) + 'T00:00:00';
  private toDate = new Date(new Date(new Date().setDate(new Date().getDate() + 90))).toISOString().substr(0, 10) + 'T23:59:59';
  private parsedPendingContainersList = [];
  private pendingContainerFilterApplied: boolean = false;
  private selectedOwnerList = [];
  private appendPendingContainersToExcel: boolean = false;
  private workingVessel: boolean = false;
  private features = {
    useCatoResponse: this.featureFlags.featureEnabled('useCatoResponse'),
  };
  public readonly parent: string = 'container';
  private subscriptions: Subscription = new Subscription();
  public containerListRequestObject = {};
  public continerDetailsRequestObject = {};
  public requestList = {};

  public getContainerStatusListResults = (parent: string): Observable<any[]> =>
    this.store.select(getContainerStatusListResults, { parent }).pipe(
      map((data) => JSON.parse(JSON.stringify(data))),
      map((data) =>
        data.map((item) => {
          item['holdsString'] =
            !!item.holds && !!item.holds.length && item.holds !== 'NONE'
              ? item.holds[0].toUpperCase() + item.holds.slice(1).toLowerCase()
              : '';
              const updatedAppointTime=this.getupdatedAppointTime(item);
              item['apptTime']=updatedAppointTime?updatedAppointTime['apptTime']:null;
              item['appStatus']=updatedAppointTime?updatedAppointTime['apptStatus']:null;
          return item;
        }),
      ),
    );

  public search$ = this.store.pipe(select(fromDashboard.getSearch , ({ parent: this.parent})));

  constructor(
    private featureFlags: FeatureFlagService,
    private timezoneService: TimezoneService,
    private store: Store,
    private router: Router,
    private api: ApiCallService,
  ) { }

  public static combineResults = (
    containers: any[],
    totalContainerCount: number,
    pending: any[],
    page: number): any => {
    const moreHits = totalContainerCount + pending.length > CONTAINER_LIST_SIZE ?
      Math.ceil((totalContainerCount + pending.length) / 50) - 1 !== page :
      false;

    const totalElements = totalContainerCount + pending.length;

    if (!pending.length) {
      return ContainerStatusListService.buildResult(containers, moreHits, totalElements);
    }

    const lastContainerPage = totalContainerCount ? Math.ceil(totalContainerCount / 50) - 1 : 0;
    if (page === lastContainerPage && !!containers.length && containers.length < 50) {
      return ContainerStatusListService.buildResult(
        containers.concat(pending.slice(0, 50 - containers.length)), moreHits, totalElements);
    }

    const firstPendingCount = pending.length ? Math.ceil(totalContainerCount / CONTAINER_LIST_SIZE) * 50 - totalContainerCount : 0;

    if (page >= lastContainerPage && (containers.length === 0)) {
      const pendingPage = page === 0 ? 0 :  page - ( (totalContainerCount > 0 && lastContainerPage > 0)? 1 + lastContainerPage: (totalContainerCount > 0)? 1: lastContainerPage);
      const start = firstPendingCount + pendingPage * CONTAINER_LIST_SIZE;
      return ContainerStatusListService.buildResult(
        Array.from(pending).splice(start, 50), moreHits, totalElements);
    }
    return ContainerStatusListService.buildResult(containers, moreHits, totalElements);
  }

  public static buildResult = (
    list: any[],
    moreHits: boolean,
    totalElements: number) => ({
    list,
    moreHits,
    totalElements,
  })

  // Look after [searchValue] equals he given value in a search tree
  // If the filters is a subtree, the root can be specified and can bu unique for that subtree
  public static isFilteredFor = (filters: any, value: any, fieldName = 'searchValue'): boolean =>
    Array.isArray(filters) ?
      filters.some(entry => ContainerStatusListService.isFilteredFor(entry, value, fieldName)) :
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      Object.keys(filters).some(key =>
        typeof filters[key] === 'object' ?
          ContainerStatusListService.isFilteredFor(filters[key], value, fieldName) :
          Array.isArray(filters[key]) ?
            filters[key].includes(value) :
            filters[key] === value,
      )

  public getContainerDetailsRequestParamToSend(action) {
    const objectToSend = {
      sourceFields: this.sourceFieldsToSend,
      orderByDirection: 'ASC',
      search: [
        containerConstants.searchFilters[0]['containerNumber'].filter,
        containerConstants.searchFilters[0]['importExport'],
      ],
      fromDate: this.fromDate,
      toDate: this.toDate,
      customFieldsRequest: {
        fetchCustomFieldsData: true,
      },
      fetchEmptiesReturn: true,
      fetchNonOrRecentlyOutgatedContainers: true,
      pottAdditionalConditions: {
        fetchPOTTAdditionalData: true,
        fetchCustomFields: true,
      },
    };

    if (action.payload.isPendingContainer) {
      objectToSend['pendingContainersRequest'] = {
        fetchPendingContainers: true,
        fetchByField: 'container',
        orderBy: 'ASC',
      };
    }
    if (action.payload.containerTripId) {
      const tripIdFilter = JSON.parse(JSON.stringify(containerConstants.searchFilters[0].containerTripId.filter));
      tripIdFilter.values.push(action.payload.containerTripId);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      objectToSend.search.push(tripIdFilter);
    }
    objectToSend.search[0].values = [action.payload.containerNumber];

    this.continerDetailsRequestObject = objectToSend;

    return objectToSend;
  }

  public getParsedContainerStatusListResponse(data, action) {
    const hasFilters = !!action.payload.paging.filters.length;

    const isPendingFilterApplied = ContainerStatusListService.isFilteredFor(
      action.payload.paging.filters,
      'pending_containers',
    );

    this.appendPendingContainersToExcel = !hasFilters || isPendingFilterApplied;

    const result = isPendingFilterApplied ?
      [] :
      data.hits.hits.map(response => {
        return this.createResponseObject(response);
      });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const pendingList = this.getPendingContainerList(data.pendingContainers);
    const totalCount = this.features.useCatoResponse ?
      data.hits.total.value :
      data.hits.total;

    return {
      content: result,
      pending: action.parent === 'container' ? pendingList : [],
      totalContainerCount: isPendingFilterApplied ?
        0 : totalCount,
      /* action.payload.customFieldApplied ?
         data.hits.nonNullCustomContainers :
         totalCount, */
      totalElements: isPendingFilterApplied ?
        data.pendingContainers.length : data.pendingContainers.length + totalCount,
      //(action.payload.customFieldApplied ? data.hits.nonNullCustomContainers : totalCount),
      size: CONTAINER_LIST_SIZE,
      number: action.payload.paging.page,
      numberOfElements: data.hits.hits.length,
      pendingApplied: this.appendPendingContainersToExcel,
    };
  }


  public getParsedContainerDetailsResponse(data, page, containerNumber, isPendingContainer) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    if ((this.enableDisableNewContainerPage(page)) || isPendingContainer) {
      if (data.hits.hits.length !== 0 || isPendingContainer) {
        let result = {};
        let timeLine = {};
        const totalCount = this.features.useCatoResponse ?
          data.hits.total.value :
          data.hits.total;
        if (isPendingContainer) {
          const pendingContainer = data.pendingContainers.filter(container => container.containerNumber === containerNumber);
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          this.parsedPendingContainersList = this.getPendingContainerList(pendingContainer);
          result = this.parsedPendingContainersList[0];
          result['unloadEvent'] = 'PENDING';
        } else {
          const filteredResponse = data.hits.hits[0];
          timeLine = this.getTimelineObject(filteredResponse);
          result = this.createResponseObject(filteredResponse);
        }
        const updatedAppointTime=this.getupdatedAppointTime(result);
        result['apptTime']=updatedAppointTime['apptTime'];
        result['appStatus']=updatedAppointTime['apptStatus'];
        return {
          isAvailableForPickup: result['isAvailableForPickup'],
          containerNumber: containerNumber,
          unloadEvent: result['unloadEvent'],
          data: result,
          totalPages: Math.ceil(totalCount / CONTAINER_LIST_SIZE),
          totalElements: totalCount,
          size: CONTAINER_LIST_SIZE,
          number: 0,
          numberOfElements: data.hits.hits.length,
          timeline: timeLine,
          note: result['note'],
        };
      }
    } else {
      return data;
    }
  }

  public applyOrderToPendingContainers(orderToApply) {
    const mappingValue = containerConstants.pendingContainersSortMappingField[orderToApply[0].property];

    return mappingValue
      ? `/${mappingValue}/${orderToApply[0].direction}`
      : '';
  }

  public getExcelDownloadEndpoint(page) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    return (this.enableDisableNewContainerPage(page))
      ? 'newContainerListExcelDownload'
      : 'download';
  }

  public getContainerDetailsPDFDownloadRequestObject(action) {
    const objectToSend = JSON.parse(JSON.stringify(this.continerDetailsRequestObject));
    const containerTripIdFilter = { ...containerConstants.searchFilters[0].containerTripId.filter };

    containerTripIdFilter.values = [action.payload.containerTripId];

    objectToSend.search.push(containerTripIdFilter);

    return objectToSend;
  }

  public getExcelDownloadRequestObjectToSend(parent, objectToSend) {
    const isPendingFilterApplied = ContainerStatusListService.isFilteredFor(
      objectToSend.filters,
      'pending_containers',
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    if (this.enableDisableNewContainerPage(parent)) {
      const selectedContainers = [];
      const containerListRequestObjectToSend = JSON.parse(JSON.stringify(this.containerListRequestObject));
      let fetchPendingContainers = true;

      containerListRequestObjectToSend.size = objectToSend.size;

      if (!this.pendingContainerFilterApplied) {
        containerListRequestObjectToSend.from = 0;
      }

      if (containerListRequestObjectToSend.customFieldsRequest.skipContainersWithNoCustomFields) {
        containerListRequestObjectToSend.customFieldsRequest.pageSize = objectToSend.size;
      }

      if (objectToSend.filters.length) {
        objectToSend.filters.map(subFilter1 => {
          subFilter1.filters.map(subFilter2 => {
            if (subFilter2.type === 'CONTAINER_NUMBER') {
              selectedContainers.push(subFilter2.searchValue);
            }
          });
        });
      }

      if (selectedContainers.length > 0 || objectToSend.selectedPendingContainers.length > 0) {
        const containerListFilter = containerConstants.searchFilters[0]['containerNumber'].filter;
        containerListFilter.values = selectedContainers;
        containerListRequestObjectToSend.search.push(containerListFilter);
      }
      if (objectToSend.selectedPendingContainers.length > 0) {
        const pendingContainerListFilter = { ...containerConstants.searchFilters[0]['pendingContainerDownload'].filter };
        pendingContainerListFilter.values = objectToSend.selectedPendingContainers;
        containerListRequestObjectToSend.pendingContainersRequest['searchOnPendingContainers'] = [pendingContainerListFilter];
      }
      if (((containerListRequestObjectToSend.search.length > 1
        || selectedContainers.length > 0)
        && objectToSend.selectedPendingContainers.length === 0)
        || (!this.appendPendingContainersToExcel && !this.pendingContainerFilterApplied)) {
        fetchPendingContainers = false;
      }
      containerListRequestObjectToSend.pendingContainersRequest.fetchPendingContainers = fetchPendingContainers;
      if (isPendingFilterApplied) {
        containerListRequestObjectToSend['fetchPendingContainersFilter'] = true;
      }

      return containerListRequestObjectToSend;
    } else {
      return objectToSend;
    }
  }

  public getUniversalSearchEndpoint(page) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    return (this.enableDisableNewContainerPage(page))
      ? 'newUniversalSearch'
      : 'unisearch';
  }

  public getUniversalSearchRequestObject(objectToSend, page) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    if (this.enableDisableNewContainerPage(page)) {
      const requestObjectToSend = JSON.parse(JSON.stringify(containerConstants.universalSearchRequestObject));
      requestObjectToSend.searchRequests.forEach(searchObject => {
        searchObject.fromDate = this.fromDate;
        searchObject.toDate = this.toDate;
        searchObject.searchParameters[0].filters[2].filterValues.push(objectToSend.searchText);
      });
      return requestObjectToSend;
    }
    return objectToSend;
  }

  public getParsedUniversalSearchResponse(response, page) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    if (this.enableDisableNewContainerPage(page)) {
      const responseToSend = JSON.parse(JSON.stringify(containerConstants.universalSearchResponseObject));
      const vesselList = response[0].response.aggregations.filter.byVesselName.buckets;
      const terminalList = response[1].response.aggregations.filter.byTerminal.buckets;
      const containerList = response[2].response.aggregations.filter.byContainer.buckets;
      //const bolList = response[3].response.aggregations.filter.byBOL.buckets;

      if (containerList.length > 0) {
        responseToSend.containerResults = containerList.map(container => container.key);
      }
      if (vesselList.length > 0) {
        responseToSend.vesselResults = vesselList.map(container => container.key);
      }
      /* if (bolList.length > 0) {
        responseToSend.bolResults = bolList.map(container => container.key);
      } */
      if (terminalList.length > 0) {
        responseToSend.terminalResults = terminalList.map(container => container.key);
      }

      return responseToSend;
    }
    return response;
  }

  private enableDisableNewContainerPage = (page: string): boolean => {
    return ['vessel', 'container', 'containerDetails', 'terminal'].includes(page);
  }

  public getPendingContainerList = (containers: any[]): any[] =>
    containers.map((container: any) => {
      return {
        ...containerConstants.pottParsedResponseObject,
        owner: {
          ...containerConstants.pottParsedResponseObject.owner,
          fullName: container.owner,
        },
        customFields: {
          ...containerConstants.pottParsedResponseObject.customFields,
          customField1: container.custom1,
          customField2: container.custom2,
        },
        note: {
          ...containerConstants.pottParsedResponseObject.note,
          body: container.notes,
        },
        containerNumber: container.containerNumber,
        isPendingContainer: true,
        flag: container.priority,
      };
    })

  public getCustomFieldsEndPoint(page) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    return (this.enableDisableNewContainerPage(page))
      ? 'newUpdateCustomFields'
      : 'updateCustomFields';
  }

  public getContainerDetailsNotesUpdateEndpoint(action) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    if (this.enableDisableNewContainerPage(action.parent)) {
      if (action.isPendingContainer) {
        return 'updatePendingContainers';
      } else {
        return 'newUpdateCustomFields';
      }
    } else {
      return 'notes';
    }
  }

  public getContainerDetailsNotesUpdateRequestObject(requestObjectToSend) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    if (this.enableDisableNewContainerPage(requestObjectToSend.parent)) {
      if (requestObjectToSend.isPendingContainer) {
        return [
          {
            container: requestObjectToSend.payload.containerNumber,
            notes: requestObjectToSend.payload.notes,
          },
        ];
      } else {
        return [requestObjectToSend.payload];
      }
    } else {
      return {
        noteText: requestObjectToSend.payload.notes,
        containerNumber: requestObjectToSend.payload.containerNumber,
      };
    }
  }

  private getTimelineObject(response) {
    return {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ata: this.timezoneService.getDateInRequiredTimeZone(response._source.ataTimeStamp || response._source.ata),
      destinationPort: response._source.portNames.portDestinationName,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      dischargedTimestamp: this.timezoneService.getDateInRequiredTimeZone(response._source.dischargeDateTime),
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      eta: this.timezoneService.getDateInRequiredTimeZone(response._source.etaTimeStamp || response._source.eta),
      origin: response._source.portNames.portOriginName,
      outgateTime: !response._source.outgateDateTime ? null
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        : this.timezoneService.getDateInRequiredTimeZone(response._source.outgateDateTime),
      shippingLine: response._source.shippingLineName,
      unloadEvent: response._source.containerStatus,
      vessel: response._source.vesselName,
      yardLocation: response._source.currentPosition,
    };
  }

  // eslint-disable-next-line max-lines-per-function
  private createResponseObject(response) {
    let pickupAvailability: boolean = null;
    if (!!response._source.isAvailableForPickup && response._source.isAvailableForPickup === 'Available') {
      pickupAvailability = true;
    } else if (response._source.isAvailableForPickup === '') {
      pickupAvailability = null;
    } else {
      pickupAvailability = false;
    }

    return {
      containerNumber: response._source.containerNumber,
      //bolNumbers: response._source.bolNumber,
      vesselName: response._source.vesselName,
      vesselCode: response._source.vesselCode,
      terminalName: response._source.destinationTerminalCode,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      eta: this.timezoneService.getDateInRequiredTimeZone(response._source.etaTimeStamp || response._source.eta),
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ata: this.timezoneService.getDateInRequiredTimeZone(response._source.ataTimeStamp || response._source.ata),
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      etaAta: this.timezoneService.getDateInRequiredTimeZone(this.getEtaAta(response)),
      holds: !response._source.allHoldsStatus || response._source.allHoldsStatus === 'CLEARED'
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      || ['RL', 'OA', 'AL'].includes(response._source.shipmentStatusCd)
        ? 'NONE'
        : response._source.allHoldsStatus,
      unloadEvent: response._source.containerStatus,
      owner: !response._source.additionalData
        ? ''
        : {
          email: '',
          familyName: '',
          fullName: response._source.additionalData.owner,
          givenName: response._source.additionalData.owner,
          id: response._source.additionalData.ownerId,
          priority: response._source.additionalData.priority,
        },
      flag: !response._source.additionalData ? '' : response._source.additionalData.priority,
      customFields: {
        customField1: !response._source.additionalData ? '' : response._source.additionalData.custom1,
        customField2: !response._source.additionalData ? '' : response._source.additionalData.custom2,
      },
      customsHoldCodes: response._source.customsHoldCodes || [],
      terminalHoldCodes: response._source.terminalHoldCodes || [],
      pendingCustHoldCodes: response._source.pendingCustHoldCodes || [],
      otherHoldCodes: response._source.otherHoldCodes || [],
      customsHoldFlag: response._source.customsHoldFlag || '',
      terminalHoldFlag: response._source.terminalHoldFlag || '',
      otherHoldFlag: response._source.otherHoldFlag || '',
      holdsPresent:
        (response._source.customsHoldFlag === 'Y')
        || (response._source.terminalHoldFlag === 'Y')
        || (response._source.otherHoldFlag === 'Y'),
      eventDate: this.timezoneService.getDateInRequiredTimeZone(this.getEventDate(response._source)),
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      location: response._source.currentPosition && !['RL', 'OA', 'AL'].includes(response._source.shipmentStatusCd)
        ? response._source.currentPosition
        : '',
      note: {
        body: !response._source.additionalData ? '' : response._source.additionalData.notes,
        editedOn: null,
      },
      isAvailableForPickup: pickupAvailability,
      typeCode: response._source.equipmentCategory,
      typeCodeDescription: response._source.equipmentCategoryDescription ? response._source.equipmentCategoryDescription : '',
      holdStatus: !response._source.allHoldsStatus
      || response._source.allHoldsStatus === 'CLEARED'
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      || ['RL', 'OA', 'AL'].includes(response._source.shipmentStatusCd)
        ? '-'
        : response._source.allHoldsStatus,
      lastFreeDay: !!response._source.lastFreeDay
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      && !['RL', 'OA', 'AL'].includes(response._source.shipmentStatusCd)
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        ? this.timezoneService.getDateInRequiredTimeZone(response._source.lastFreeDay)
        : null,
      outgatedTime: response._source.outgateDateTime
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        ? this.timezoneService.getDateInRequiredTimeZone(response._source.outgateDateTime)
        : null,
      shipmentStatusCode: response._source.containerStatus,
      berth: response._source.berthCode,
      chassisNumber: response._source.chassisNumber || '',
      destination: response._source.portNames.portDestinationName,
      origin: response._source.portNames.portOriginName,
      hazardous: response._source.isHazardous ? this.getHazardousValue(response._source.hazMatClassCode) : null,
      modalityWithScac: this.getModalitySCAC(response),
      shippingLine: response._source.shippingLineName,
      vessel: response._source.vesselName,
      voyageNumber: response._source.voyageNumber,
      yardLocation: !!response._source.currentPosition &&
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      !['RL', 'OA', 'AL'].includes(response._source.shipmentStatusCd)
        ? response._source.currentPosition
        : '',
      terminalHold: !!response._source.terminalHoldFlag
      && response._source.terminalHoldFlag === 'Y'
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      && !['RL', 'OA', 'AL'].includes(response._source.shipmentStatusCd)
        ? !!response._source.terminalHoldCodes && !!response._source.terminalHoldCodes.length
          ? response._source.terminalHoldCodes.join(',')
          : 'Yes' // Mocking codes to Yes to handle length in HTML
        : 'Cleared',
      otherHold: !!response._source.otherHoldFlag
      && response._source.otherHoldFlag === 'Y'
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      && !['RL', 'OA', 'AL'].includes(response._source.shipmentStatusCd)
        ? !!response._source.otherHoldCodes && !!response._source.otherHoldCodes.length
          ? response._source.otherHoldCodes.join(',')
          : 'Yes' // Mocking codes to Yes to handle length in HTML
        : 'Cleared',
      isPendingContainer: false,
      containerTripId: response._source.containerTripId,
      customHolds: !!response._source.customsHoldFlag
      && response._source.customsHoldFlag === 'Y'
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      && !['RL', 'OA', 'AL'].includes(response._source.customsHoldFlag)
        ? !!response._source.customsHoldCodes && !!response._source.customsHoldCodes.length
          ? response._source.customsHoldCodes
          : ['Yes'] // Mocking codes to Yes to handle length in HTML
        : [],
      tripId: response._source.tripId,
      apptStatus: AppointmentStatusTypes[response._source.appointmentStatusMapper],
      apptTimeInterval: {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        from: response._source.appointmentDate ? this.timezoneService.getDateInRequiredTimeZone(response._source.appointmentDate) : null,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        to: response._source.appointmentSlotEndDateTime ? this.timezoneService.getDateInRequiredTimeZone(response._source.appointmentSlotEndDateTime) : null,
      },
      appointmentSlotEndDateTime:response._source.appointmentSlotEndDateTime,
      appointmentStatusMapper:response._source.appointmentStatusMapper,
      shipmentStatusCd:response._source.shipmentStatusCd,
      averageBerthTime:response._source.vesselNameData?.avgBerthTime,
      averageDischargeTime:response._source.vesselNameData?.avgDischargeTime,
      averageLoadTime:response._source.vesselNameData?.avgLoadTime,
      emptyReturns: response._source.emptiesReturn,
      dischargeDateTime: response._source && response._source.dischargeDateTime,
      outgateDateTime: response._source && response._source.outgateDateTime,
      shippingLineSCAC: response._source.shippingLineSCAC,
      ingateTerminalCd: response._source.ingateTerminalCd? response._source.ingateTerminalCd: null,
      outgateTerminalCd: (response._source.outgateTerminalCd && response._source.outgateDateTimeByTruck)? response._source.outgateTerminalCd: null,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ingateDateTime: response._source.ingateDateTime? this.timezoneService.getDateInRequiredTimeZone(response._source.ingateDateTime): null,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      outgateDateTimeByTruck: response._source.outgateDateTimeByTruck? this.timezoneService.getDateInRequiredTimeZone(response._source.outgateDateTimeByTruck): null,
      totalTime: response._source.totalTime,
      carrierName: response._source.carrierName? response._source.carrierName: null,
      etaTimeStamp: response._source.etaOrEtaTimeStamp,
      isSpecialHandlingEnabled: response._source.isSpecialHandlingEnabled ? response._source.isSpecialHandlingEnabled : false,
    };
  }

  private getHazardousValue(hazClassCode) {
    return hazClassCode !== null
      ? 'Class ' + hazClassCode
      : hazClassCode;
  }

  private getModalitySCAC(response) {
    let result = '';

    if (response && response._source && response._source.destinationMode) {
      switch (response._source.destinationMode.toLowerCase()) {
        case 'local':
        case 'truck': {
          result = `Truck${response._source.truckerSCAC ? ' (' + response._source.truckerSCAC + ')' : ''}`;
          break;
        }
        case 'rail':
        case 'train': {
          result = `Rail${response._source.railRoadSCAC ? ' (' + response._source.railRoadSCAC + ')' : ''}`;
          break;
        }
        case 'na': {
          break;
        }
        case 'vessel': {
          result = `Vessel${response._source.vesselOwnerSCAC ? ' (' + response._source.vesselOwnerSCAC + ')' : ''}`;
          break;
        }
      }
    }
    return result;
  }

  // eslint-disable-next-line max-lines-per-function
  public getRequestObjectToSend(action) {
    let filtersToApply = action.payload.paging.filters;
    if (filtersToApply && filtersToApply.length >= 0) {
      this.workingVessel = false;
    }
    filtersToApply?.[0]?.filters?.map(filtersToApply => {
      const filtersIterate = filtersToApply && ['AND', 'OR'].includes(filtersToApply.type)
        ? filtersToApply.filters
        : filtersToApply ? [filtersToApply] : [];
    
      filtersIterate?.map(filtersApply => {
        if (filtersApply && filtersApply.type === WORKING_VESSELS) {
          this.workingVessel = true;
        } else {
          filtersApply?.filters?.map(filterWorking => {
            if (filterWorking && filterWorking.type === WORKING_VESSELS) {
              this.workingVessel = true;
            }
          });
        }
      });
    });

    const orderToApply = action.payload.paging.orders;
    const fromValue = Math.ceil((action.payload.paging.page * CONTAINER_LIST_SIZE));
    const containerConstantSearchFilter = JSON.parse(JSON.stringify(containerConstants.searchFilters));
    let objectToSend = {
      from: fromValue,
      size: CONTAINER_LIST_SIZE,
      sourceFields: this.sourceFieldsToSend,
      orderByDirection: 'ASC',
      search: [containerConstantSearchFilter[0]['importExport'],containerConstantSearchFilter[0]['portDestination']],
      fromDate: this.fromDate,
      toDate: this.toDate,
      fetchNonOrRecentlyOutgatedContainers: true,
      pottAdditionalConditions: {
        fetchPOTTAdditionalData: true,
        fetchCustomFields: true,
      },
      pendingContainersRequest: {
        fetchPendingContainers: true,
        fetchByField: 'container',
        orderBy: 'ASC',
      },
      specialHandlingRequestFilter: {
        identifyContainersWithSpecialHandling: true,
      },
      customFieldsRequest: {
        fetchCustomFieldsData: true,
        skipContainersWithNoCustomFields: false,
        pageSize: CONTAINER_LIST_SIZE,
        filterOnCustomFieldsData: [],
      },
      fetchEmptiesReturn: true,
      fetchWorkingVessel: this.workingVessel,
      fetchEmptiesOutgatedContainers: false,
      fetchEmptiesReturnedContainers: false,
    };
    let customDataSortApplied = false;
    this.pendingContainerFilterApplied = action.payload.applyPending;
    this.selectedOwnerList = [];

    if (this.router.url.split('/').at(-1) === 'outgated') {
      filtersToApply = [
        { type: 'AND', filters: [{ type: 'OR', filters: [{ type: 'UNLOAD_EVENT', searchValue: 'Truck Outgated' }] }] },
      ];
      this.search$.pipe(filter((value) => value !== undefined && value.length>0))
      .subscribe((searchValue) => {
        const containerSearchFilter = containerConstantSearchFilter[0]['containerSearch'].filter;
        containerSearchFilter.values.push(searchValue);
        objectToSend.search.push(containerSearchFilter);
      });
      objectToSend.fetchEmptiesOutgatedContainers = true;
      delete objectToSend['fetchEmptiesReturnedContainers'];
    } else if (this.router.url.split('/').at(-1) === 'returned') {
      filtersToApply = [
        { type: 'AND', filters: [{ type: 'OR', filters: [{ type: 'UNLOAD_EVENT', searchValue: 'Truck Returned' }] }] },
      ];
      objectToSend.fetchEmptiesReturnedContainers = true;
      objectToSend.search.forEach((item) => {
        if (item.key === 'importExport') {
          item.values = ['I', 'E'];
        }
        this.search$.pipe(filter((value) => value !== undefined && value.length>0))
        .subscribe((searchValue) => {
          const containerSearchFilter = containerConstantSearchFilter[0]['containerSearch'].filter;
          containerSearchFilter.values.push(searchValue);
          objectToSend.search.push(containerSearchFilter);
        });
      });
      delete objectToSend['fetchEmptiesOutgatedContainers'];
    } else {
      delete objectToSend['fetchEmptiesReturnedContainers'];
      delete objectToSend['fetchEmptiesOutgatedContainers'];
    }

    if (orderToApply) {
      delete objectToSend['orderBy'];
      delete objectToSend.pottAdditionalConditions['sortOnScriptField'];
      delete objectToSend.pottAdditionalConditions['sortByDirection'];
      orderToApply.map(order => {
        const orderFieldName = containerConstants.customDataSortMappingField[order.property];
        const pendingContainerOrder = containerConstants.pendingContainersSortMappingField[order.property];
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        if (['etaAta', 'holdStatusAsEnum', 'lastFreeDay', 'location', 'unloadEvent', 'eventdate'].includes(order.property)) {
          objectToSend.pottAdditionalConditions[containerConstants.applySort[order.property]] =
            containerConstants.sortOnScriptFieldValues[order.property];
          objectToSend.pottAdditionalConditions['sortByDirection'] = order.direction;
        } else if (orderFieldName) {
          objectToSend.customFieldsRequest['sortOnField'] = orderFieldName;
          objectToSend.customFieldsRequest['sortOrder'] = order.direction;
          customDataSortApplied = true;
        } else {
          objectToSend['orderBy'] = containerConstants.applySort[order.property];
          objectToSend['orderByDirection'] = order.direction;
        }
        if (pendingContainerOrder) {
          objectToSend.pendingContainersRequest.fetchByField = pendingContainerOrder;
          objectToSend.pendingContainersRequest.orderBy = order.direction;
        }
      });
    }

    if (filtersToApply && filtersToApply.length) {
      const searchFiltersToSend = filtersToApply[0].filters;
      const vesselFilter = containerConstantSearchFilter[0]['VESSEL_CODE'].filter;
      const tripIdFilter = containerConstantSearchFilter[0]['TRIP_ID'].filter;
      let addVesselFilter = false;
      let universalSearchFilterApplied = false;
      let addTripIdFilter = false;
      let appliedFiltersObject = {
        addStatusFilter: false,
        addHoldsFilter: false,
        addVesselNameFilter: false,
        addTerminalFilter: false,
        addBOLNumberFilter: false,
        addLastAddedFilter: false,
        addContainerNumberFilter: false,
        statusFilter: containerConstantSearchFilter[0]['UNLOAD_EVENT'].filter,
        holdsFilter: containerConstantSearchFilter[0]['HOLDS'].filter,
        vesselNameFilter: containerConstantSearchFilter[0]['vesselNameFilter'].filter,
        terminalFilter: containerConstantSearchFilter[0]['terminalFilter'].filter,
     /*    bolNumberFilter: containerConstantSearchFilter[0]['bolNumberFilter'].filter, */
        containerNumberFilter: containerConstantSearchFilter[0]['containerNumber'].filter,
        searchOnScriptField: false,
        scriptFiledFilter: containerConstantSearchFilter[0]['UNLOAD_EVENT'].searchFilter,
        pendingContainersFilter: false,
        addAvailableFilter: false,
        availableFilter: containerConstantSearchFilter[0]['available'].filter,
        eliminateOugated: containerConstantSearchFilter[0]['available'].eliminateOugated,
        addMyContainerOrOwnerFilter: false,
        addPriorityFilter: false,
        priorityValues: [],
        addContainerSizeFilter: false,
        containerSizeFilter: containerConstantSearchFilter[0]['containerSize'].filter,
        addWorkingVesselsFilter: false,
        workingVesselsFilters: containerConstantSearchFilter[0]['workingVessels'].filter,
        tripIdFilter: tripIdFilter,
      };
      if (searchFiltersToSend.length === 6 || (searchFiltersToSend.type === 'OR' && searchFiltersToSend.filters.length === 6)) {
        const universalSearchFilter = JSON.parse(JSON.stringify(containerConstantSearchFilter[0]['universalSearch'].filter));
        const searchValue = searchFiltersToSend.type === 'OR'
          ? searchFiltersToSend.filters[0].searchValue
          : searchFiltersToSend[0].searchValue;
        universalSearchFilterApplied = true;
        universalSearchFilter.values.push(searchValue);
        objectToSend.search.push(universalSearchFilter);
      }
      searchFiltersToSend.map(subFilter1 => {
        if (action.parent === 'container') {
          appliedFiltersObject = this.getAppliedFiltersRequestParams(subFilter1, appliedFiltersObject, universalSearchFilterApplied);
        } else if (action.parent === 'vessel' || action.parent === 'terminal') {
          if (subFilter1.type === 'OR' || subFilter1.type === 'AND') {
            subFilter1.filters.map(subFilter2 => {
              if (subFilter2.type === 'VESSEL_CODE') {
                addVesselFilter = true;
                vesselFilter.values = [subFilter2.searchValue];
              } else if (subFilter2.type === TRIP_ID) {
                addTripIdFilter = true;
                tripIdFilter.values = [subFilter2.searchValue];
              } else {
                appliedFiltersObject = this.getAppliedFiltersRequestParams(subFilter2, appliedFiltersObject, universalSearchFilterApplied);
              }
            });
          } else if (subFilter1.type === 'VESSEL_CODE') {
            addVesselFilter = true;
            vesselFilter.values = [subFilter1.searchValue];
          } else if (subFilter1.type === TRIP_ID) {
            addTripIdFilter = true;
            tripIdFilter.values = [subFilter1.searchValue];
          } else if (subFilter1.type === 'TERMINAL_NAME') {
            const terminalFilter = containerConstantSearchFilter[0]['terminalFilter'].filter;
            terminalFilter.values = [subFilter1.searchValue];
            objectToSend.search.push(terminalFilter);
          }
        }
      });
      if (addVesselFilter) {
        objectToSend.search.push(vesselFilter);
      }
      if (appliedFiltersObject.addStatusFilter) {
        objectToSend.search.push(appliedFiltersObject.statusFilter);
      }
      if (appliedFiltersObject.addHoldsFilter) {
        objectToSend.search.push(appliedFiltersObject.holdsFilter);
        objectToSend.search.push(appliedFiltersObject.eliminateOugated);
      }
      if (appliedFiltersObject.addVesselNameFilter) {
        objectToSend.search.push(appliedFiltersObject.vesselNameFilter);
      }
      /* if (appliedFiltersObject.addBOLNumberFilter) {
        objectToSend.search.push(appliedFiltersObject.bolNumberFilter);
      } */
      if (appliedFiltersObject.addTerminalFilter) {
        objectToSend.search.push(appliedFiltersObject.terminalFilter);
      }
      if (appliedFiltersObject.searchOnScriptField) {
        objectToSend.pottAdditionalConditions['searchOnScriptField'] = [appliedFiltersObject.scriptFiledFilter];
      }
      if (appliedFiltersObject.addAvailableFilter) {
        objectToSend.search.push(appliedFiltersObject.availableFilter);
        objectToSend.search.push(appliedFiltersObject.eliminateOugated);
      }
      if (appliedFiltersObject.addLastAddedFilter) {
        objectToSend.pottAdditionalConditions['fetchLastAddedContainers'] = true;
      }
      this.pendingContainerFilterApplied = appliedFiltersObject.pendingContainersFilter;
      if (appliedFiltersObject.addMyContainerOrOwnerFilter && !this.pendingContainerFilterApplied) {
        const ownerFilter = containerConstantSearchFilter[0]['owner'].filter;
        ownerFilter.values = this.selectedOwnerList;
        objectToSend.customFieldsRequest.filterOnCustomFieldsData.push(ownerFilter);
      }
      if (appliedFiltersObject.addPriorityFilter) {
        const priorityFilter = containerConstantSearchFilter[0]['priority'].filter;
        priorityFilter.values = appliedFiltersObject.priorityValues;
        objectToSend.customFieldsRequest.filterOnCustomFieldsData.push(priorityFilter);
      }
      if (appliedFiltersObject.addMyContainerOrOwnerFilter || appliedFiltersObject.addPriorityFilter) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        objectToSend = this.updateCustomFieldsRequest(objectToSend, fromValue, action.payload.containerCount || 0);
      } else if (customDataSortApplied) {
        delete objectToSend.customFieldsRequest.pageSize;
        delete objectToSend.customFieldsRequest.filterOnCustomFieldsData;
        delete objectToSend.customFieldsRequest.skipContainersWithNoCustomFields;
      }
      if (appliedFiltersObject.addContainerSizeFilter) {
        objectToSend.search.push(appliedFiltersObject.containerSizeFilter);
      }
      if (appliedFiltersObject.addContainerNumberFilter) {
        objectToSend.search.push(appliedFiltersObject.containerNumberFilter);
      }
      if (addTripIdFilter) {
        objectToSend.search.push(tripIdFilter);
      }
    }

    this.containerListRequestObject = objectToSend;
    return objectToSend;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private updateCustomFieldsRequest(objectToUpdate: any, fromValue: number, count: number = 0) {
    objectToUpdate.customFieldsRequest.skipContainersWithNoCustomFields = true;
    objectToUpdate.customFieldsRequest['fromContainers'] = 0;
    objectToUpdate.from = fromValue;

    return objectToUpdate;
  }

  // eslint-disable-next-line max-lines-per-function
  private getAppliedFiltersRequestParams(filter, filtersObjectToSend, universalSearchFilterApplied) {
    const statusPossibleValues = containerConstants.searchFilters[0]['UNLOAD_EVENT'].possibleValues;
    const holdsPossibleValues = containerConstants.searchFilters[0]['HOLDS'].possibleValues;
    const statusCodes = [];
    const scriptSearchFilter = [];
    const holdCodes = {
      'keyValue': [],
      'Values': [],
      'NONE': false,
      'TERMINAL': false,
      'CUSTOMS': false,
    };
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    if (['AND', 'OR'].includes(filter.type)) {
      filter.filters.map(subFiltersToApply => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        const filtersToIterate = ['AND', 'OR'].includes(subFiltersToApply.type)
          ? subFiltersToApply.filters
          : [subFiltersToApply];
        filtersToIterate.map(filtersToApply => {
          if (filtersToApply.type === UNLOAD_EVENT) {
            if ([containerConstants.containerStatusEnum.DOCKED,
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              containerConstants.containerStatusEnum.ON_VESSEL].includes(filtersToApply.searchValue)) {
              scriptSearchFilter.push(filtersToApply.searchValue);
            } else {
              filtersObjectToSend.addStatusFilter = true;
              // eslint-disable-next-line prefer-spread
              statusCodes.push.apply(statusCodes, statusPossibleValues[filtersToApply.searchValue]);
              filtersObjectToSend.statusFilter.values = statusCodes;
            }
          } else if (filtersToApply.type === HOLDS) {
            filtersObjectToSend.addHoldsFilter = true;
            holdCodes.keyValue.push(holdsPossibleValues[filtersToApply.searchValue]);
            holdCodes.Values.push('Y');
            filtersObjectToSend.holdsFilter.key = holdCodes.keyValue.join(',');
            filtersObjectToSend.holdsFilter.values = holdCodes.Values;
          } else if (filtersToApply.type === PENDING_CONTAINERS) {
            filtersObjectToSend.pendingContainersFilter = true;
          } else if (filtersToApply.type === OWNER) {
            filtersObjectToSend.addMyContainerOrOwnerFilter = true;
            this.selectedOwnerList.push(filtersToApply.searchValue);
          } else if (filtersToApply.type === VESSEL_NAME && !universalSearchFilterApplied) {
            filtersObjectToSend.addVesselNameFilter = true;
            filtersObjectToSend.vesselNameFilter.values.push(filtersToApply.searchValue);
          } else if (filtersToApply.type === TERMINAL_NAME && !universalSearchFilterApplied) {
            filtersObjectToSend.addTerminalFilter = true;
            filtersObjectToSend.terminalFilter.values.push(filtersToApply.searchValue);
          } /* else if (filtersToApply.type === BOL_NUMBER && !universalSearchFilterApplied) {
            filtersObjectToSend.addBOLNumberFilter = true;
            filtersObjectToSend.bolNumberFilter.values.push(filtersToApply.searchValue);
          } */ else if (filtersToApply.type === IS_AVAILABLE_FOR_PICKUP) {
            filtersObjectToSend.addAvailableFilter = true;
          } else if (filtersToApply.type === LAST_CLAIMED) {
            filtersObjectToSend.addLastAddedFilter = true;
          } else if (filtersToApply.type === FLAG) {
            filtersObjectToSend.addPriorityFilter = true;
            filtersObjectToSend.priorityValues.push(filtersToApply.searchValue);
          } else if (filtersToApply.type === CONTAINER_LENGTH) {
            filtersObjectToSend.addContainerSizeFilter = true;
            filtersObjectToSend.containerSizeFilter.values.push(filtersToApply.searchValue);
          } else if (filtersToApply.type === CONTAINER_NUMBER) {
            filtersObjectToSend.addContainerNumberFilter = true;
            filtersObjectToSend.containerNumberFilter.values.push(filtersToApply.searchValue);
          } else if (filtersToApply.type === TRIP_ID) {
            filtersObjectToSend.addTripIdFilter = true;
            filtersObjectToSend.tripIdFilter.values.push(filtersToApply.searchValue);
          } else if (filtersToApply.type === WORKING_VESSELS) {
            filtersObjectToSend.addWorkingVesselsFilter = true;
          } else if (filtersToApply.type === TERMINAL) {
            filtersObjectToSend.addTerminalFilter = true;
            filtersObjectToSend.terminalFilter.values.push(filtersToApply.searchValue);
          } else if (filtersToApply.type === VESSEL_FILTER) {
            filtersObjectToSend.addVesselNameFilter = true;
            filtersObjectToSend.vesselNameFilter.values.push(filtersToApply.searchValue);
          } 
        });
      });
    } else if (filter.type === VESSEL_NAME && !universalSearchFilterApplied) {
      filtersObjectToSend.addVesselNameFilter = true;
      filtersObjectToSend.vesselNameFilter.values.push(filter.searchValue);
    } else if (filter.type === TERMINAL_NAME && !universalSearchFilterApplied) {
      filtersObjectToSend.addTerminalFilter = true;
      filtersObjectToSend.terminalFilter.values.push(filter.searchValue);
    }/*  else if (filter.type === BOL_NUMBER && !universalSearchFilterApplied) {
      filtersObjectToSend.addBOLNumberFilter = true;
      filtersObjectToSend.bolNumberFilter.values.push(filter.searchValue);
    }  */else if (filter.type === TRIP_ID) {
      filtersObjectToSend.addTripIdFilter = true;
      filtersObjectToSend.tripIdFilter.values.push(filter.searchValue);
    }

    if (scriptSearchFilter.length > 0) {
      filtersObjectToSend.searchOnScriptField = true;
      filtersObjectToSend.scriptFiledFilter.values = scriptSearchFilter;
      if (filtersObjectToSend.addStatusFilter) {
        filtersObjectToSend.statusFilter.comparison = 'SHOULD';
      }
    }

    return filtersObjectToSend;
  }

  private getEventDate(response: any): number {
    let result = 0;

    switch (response.containerStatus) {
      case containerConstants.containerStatusEnum.ON_VESSEL: {
        result = response.etaTimeStamp ? response.etaTimeStamp : response.eta;
        break;
      }
      case containerConstants.containerStatusEnum.DOCKED: {
        result = response.ataTimeStamp;
        break;
      }
      case containerConstants.containerStatusEnum.DISCHARGED: {
        result = response.dischargeDateTime;
        break;
      }
      case containerConstants.containerStatusEnum.OUTGATED: {
        result = response.outgateDateTime;
        break;
      }
    }
    return result;
  }

  public getEtaAta(response) {
    return response._source.etaAta
        || response._source.ataOrAtaTimeStamp
        || response._source.etaOrEtaTimeStamp;
  }

  private formatApptTime(
    apptStatus: AppointmentStatusTypes,
    apptTimeInterval: { from: number | Date; to: number | Date },
  ): string {
    const apptTimeFrom = apptTimeInterval.from ? format(new Date(apptTimeInterval.from), 'dd MMM | HH:mm') : null;
    const isTimeRange = apptStatus === AppointmentStatusTypes.BOOKED || AppointmentStatusTypes.FULFILLED && apptTimeInterval.to;

    if (!apptTimeFrom) {
      return;
    }

    return isTimeRange ? `${apptTimeFrom} - ${format(new Date(apptTimeInterval.to), 'HH:mm')}` : apptTimeFrom;
  }

private getupdatedAppointTime(data:any) {
 // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
 if (data && data['shipmentStatusCd'] && ['RL', 'OA', 'AL','AE'].includes(data['shipmentStatusCd']) && data['appointmentStatusMapper'] && ['BOOKED','CANCELED'].includes(data['appointmentStatusMapper'])) {
    data['apptTime']='-';
    data['apptStatus'] ='-';
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  } else if (data && data['shipmentStatusCd'] && !['RL', 'OA', 'AL','AE'].includes(data['shipmentStatusCd']) && data['appointmentStatusMapper'] && 'CANCELED'.includes(data['appointmentStatusMapper']) ) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    data['apptTime'] =  data['appointmentSlotEndDateTime'] ? format(new Date(this.timezoneService.getDateInRequiredTimeZone(data['appointmentSlotEndDateTime'])), 'dd MMM | HH:mm') : null;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  } else if (data && data['shipmentStatusCd'] && ['RL', 'OA', 'AL','AE'].includes(data['shipmentStatusCd']) && data['appointmentStatusMapper'] && 'FULFILLED'.includes(data['appointmentStatusMapper'])) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    data['apptTime'] =  data['appointmentSlotEndDateTime'] ? format(new Date(this.timezoneService.getDateInRequiredTimeZone(data['appointmentSlotEndDateTime'])), 'dd MMM | HH:mm') : null;
  } else {
    if (data && data['apptStatus'] && data['apptTimeInterval']) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    data['apptTime'] = this.formatApptTime(data['apptStatus'], data['apptTimeInterval']);
   }
  }
  return data;
}

}
