import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedModule } from './shared.module';
import { DynamicFormModule } from '@shared/dynamic-form/dynamic-form.module';

import { StoreModule, ActionReducerMap } from '@ngrx/store';

import { ModalReducer } from '@store/modal/modal.reducers';
import { CustomFieldsReducer } from '@store/custom-fields/custom-fields.reducer';

import { AddContainersModalComponent } from './components/add-containers-modal/add-containers-modal.component';
import { TermsOfUseModalComponent } from './components/terms-of-use-modal/terms-of-use-modal.component';
import { EditContainersModalComponent } from './components/edit-containers-modal/edit-containers-modal.component';
import { HoldsModalComponent } from './components/holds-modal/holds-modal.component';
import { UnclaimContainerModalComponent } from './components/unclaim-container-modal/unclaim-container-modal.component';
import { PromptModalComponent } from './components/prompt-modal/prompt-modal.component';
import { SessionTimeoutModalComponent } from './components/session-timeout-modal/session-timeout-modal.component';
import { UserPreferencesComponent } from '@shared/components/user-preferences-modal/user-preferences-modal.component';
import { LandingPageSettingsComponent } from '@shared/components/landing-page-settings/landing-page-settings.component';
import { TableSettingsModalComponent } from '@shared/components/table-settings-modal/table-settings-modal.component';
import { UserPreferencesNotificationComponent } from './components/user-preferences-notification/user-preferences-notification.component';
import { AcknowledgeContainersModalComponent } from './components/acknowledge-containers-modal/acknowledge-containers-modal.component';
import { AppointmentsModalComponent } from './components/appointments-modal/appointments-modal.component';
import { NewWindowConfirmModalComponent } from './components/new-window-confirm-modal/new-window-confirm-modal.component';
import { IntermodalClaimModalComponent } from './components/intermodal-claim-modal/intermodal-claim-modal.component';
import { PasswordChangeModalComponent } from '@shared/components/password-change-modal/password-change-modal.component';
import { RequestSpecialHandlingModalComponent } from './components/request-specialhandling-modal/request-specialhandling-modal.component';
import { RequestCancelModalComponent } from './components/request-cancel-modal/request-cancel-modal.component';
import { RequestContainerListComponent } from './components/request-container-list/request-container-list.component';
import { RequestSpecialhandlingTableComponent } from './components/request-container-list/request-specialhandling-table/request-specialhandling-table.component';
import { RequestContainerRemoveModelComponent } from './components/request-container-remove-model/request-container-remove-model.component';
import { WhatsNewModalComponent } from './components/whats-new-modal/whats-new-modal.component';
import { ScheduleAppointmentSignupModalComponent } from './components/schedule-appointment-signup-modal/schedule-appointment-signup-modal.component';

const REDUCERS: ActionReducerMap<any> = {
  'dialog': ModalReducer,
  'customFields': CustomFieldsReducer,
};

const COMPONENTS = [
  AddContainersModalComponent,
  TermsOfUseModalComponent,
  AppointmentsModalComponent,
  NewWindowConfirmModalComponent,
  EditContainersModalComponent,
  HoldsModalComponent,
  UnclaimContainerModalComponent,
  PromptModalComponent,
  SessionTimeoutModalComponent,
  UserPreferencesComponent,
  LandingPageSettingsComponent,
  UserPreferencesNotificationComponent,
  TableSettingsModalComponent,
  AcknowledgeContainersModalComponent,
  IntermodalClaimModalComponent,
  PasswordChangeModalComponent,
  RequestSpecialHandlingModalComponent,
  RequestCancelModalComponent,
  RequestContainerListComponent,
  RequestSpecialhandlingTableComponent,
  RequestContainerRemoveModelComponent,
  WhatsNewModalComponent,
  ScheduleAppointmentSignupModalComponent,
];

@NgModule({
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
  imports: [
    CommonModule,
    
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    DynamicFormModule,
    StoreModule.forFeature('modal', REDUCERS),
  ],
})
export class ModalModule { }
