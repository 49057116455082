import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { mergeMap, map, catchError, tap } from 'rxjs/operators';
import { Store, Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { State } from '@store/app.state';
import { ApiQueryBuilder } from '@services/api-query-builder';
import * as CustomFieldsActions from './custom-fields.actions';
import { ApiCallService } from '@services/api-call.service';
import { ContainerStatusListService } from '@services/container-status-list.service';
import { FeatureFlagService } from '@app/core/services/feature-flag.service';
import { DashboardActions } from '@store/dashboard/dashboard.actions';

@Injectable()
export class CustomFieldsEffect {
  public readonly features = {
    updatePendingContainers: this.featureFlags.featureEnabled('updatePendingContainers'),
  };

  constructor(
    private actions$: Actions,
    private api: ApiCallService,
    private store: Store<State>,
    private featureFlags: FeatureFlagService,
    private containerStatusListService: ContainerStatusListService) { }

  public SetCustomFields$: Observable<Action> = createEffect(() =>this.actions$.pipe(
    ofType<CustomFieldsActions.GetCustomFields>(CustomFieldsActions.GET_CUSTOM_FIELDS_DATA),
    mergeMap(action => {
      return this.api
        .constructApiCall(
          new ApiQueryBuilder()
            .addName('customFields')
            .addBody(action.payload)
            .build(),
        )
        .pipe(
          map(data => {
            return new CustomFieldsActions.GetCustomFieldsSuccess(data);
          }),
          catchError(error => {
            return of(new CustomFieldsActions.GetCustomFieldsError(error));
          }),
        );
    }),
  ),
  );

  public UpdateCustomFields$ = createEffect(() => this.actions$
    .pipe(
      ofType<CustomFieldsActions.UpdateCustomFields>(CustomFieldsActions.UPDATE_CUSTOM_FIELDS),
      mergeMap(action => {
        return this.api.constructApiCall(
          new ApiQueryBuilder()
            .addName(this.containerStatusListService.getCustomFieldsEndPoint(action.parent))
            .addBody(action.payload)
            .build(),
        )
          .pipe(
            map(() => {
              return new CustomFieldsActions.UpdateCustomFieldsSuccess();
            }),
            tap(() => {
              if (action.parent !== 'vessel') {
                this.store.dispatch(DashboardActions.updateListAndChart({ parent: action.parent }));
              }
            }),
            catchError(() => of(new CustomFieldsActions.UpdateCustomFieldsError(action.parent))),
          );
      }),
    ),
    );

  public UpdatePendingContainerCustomFields$ = createEffect(() => this.actions$
    .pipe(
      ofType<CustomFieldsActions.UpdatePendingContainerCustomFields>(CustomFieldsActions.UPDATE_PENDING_CONTAINER_CUSTOM_FIELDS),
      mergeMap(action => {
        return this.api.constructApiCall(
          new ApiQueryBuilder()
            .addName('updatePendingContainers')
            .addBody(action.payload)
            .build(),
        )
          .pipe(
            map(() => {
              return new CustomFieldsActions.UpdatePendingContainerCustomFieldsSuccess();
            }),
            tap(() => {
              if (action.parent !== 'vessel') {
                this.store.dispatch(DashboardActions.updateListAndChart({ parent: action.parent }));
              }
            }),
            catchError(() => of(new CustomFieldsActions.UpdatePendingContainerCustomFieldsError(action.parent))),
          );
      }),
    ),
    );

  public UpdateFlag$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType<CustomFieldsActions.UpdateFlag>(CustomFieldsActions.UPDATE_FLAG),
      mergeMap(action => {
        if (this.features.updatePendingContainers && action.payload.isPendingContainer) {
          const payloadData = {
            'container': action.payload.containerNumber,
            'priority': action.payload.flag === ''
              ? 'NONE'
              : action.payload.flag,
          };
          return this.api.constructApiCall(
            new ApiQueryBuilder()
              .addName('updatePendingContainers')
              .addBody([payloadData])
              .build(),
          )
            .pipe(
              map(data => new CustomFieldsActions.UpdateFlagSuccess(data, action.parent)),
              catchError(() => of(new CustomFieldsActions.UpdateFlagError(action.parent))),
            );
        } else {
          const payloadData = {
            'containerNumber': action.payload.containerNumber,
            'priority': action.payload.flag === ''
              ? 'NONE'
              : action.payload.flag,
            'tripId': '' + action.payload.tripId + '',
          };
          if (action.payload.owner !== '') {
            payloadData['firstCustomField'] = action.payload.owner.customFields.customField1;
            payloadData['secondCustomField'] = action.payload.owner.customFields.customField2;
            payloadData['notes'] = action.payload.owner.notes;
            payloadData['owner'] = action.payload.owner.fullName;
            payloadData['ownerId'] = action.payload.owner.id;
          }
          return this.api.constructApiCall(
            new ApiQueryBuilder()
              .addName('newUpdateCustomFields')
              .addBody([payloadData])
              .build(),
          )
            .pipe(
              map(data => new CustomFieldsActions.UpdateFlagSuccess(data, action.parent)),
              tap(() => {
                this.store.dispatch(DashboardActions.updateListAndChart({ parent: action.parent }));
            }),
              catchError(() => of(new CustomFieldsActions.UpdateFlagError(action.parent))),
            );
        }
      }),
    ),
    );
}
