import { createFeatureSelector, createSelector } from '@ngrx/store';

import { VesselState } from './vessel.state';
import * as VesselActions from './vessel.actions';
import { Vessel } from '@app/shared/models/dashboard/vessel.model';
import { IVesselSimple } from '@app/shared/models/dashboard/notification.model';

export type Action = VesselActions.All;

const initialState: VesselState = {
  results: [],
  firstVesselsCode: '',
  loading: false,
  error: false,
  moreHits: false,
  totalElements: 0,
  page: 0,
  filter: [],
  allVessels: [],
  VesselList: [],
};

const getVesselState = createFeatureSelector<VesselState>('vessel');

export const getVesselList = createSelector(
  getVesselState,
  state => state.results,
);

export const getAllVesselList = createSelector(
  getVesselState,
  state => state.allVessels,
);

export const fetchALLVesselList = createSelector(
  getVesselState,
  state => state.VesselList,
);

export const getFirstVesselsCode = createSelector(
  getVesselState,
  state => state.firstVesselsCode,
);

export const getVesselListError = createSelector(
  getVesselState,
  state => state.error,
);

export const isVesselListLoading = createSelector(
  getVesselState,
  state =>
    ({
      loading: state.loading,
      page: state.page,
    }),
);

export const isMoreHitsLoading = createSelector(
  isVesselListLoading,
  (loadingState) => loadingState.loading && loadingState.page > 0,
);

export const isMoreHits = createSelector(
  getVesselState,
  state => state.moreHits,
);

export const getTotalElements = createSelector(
  getVesselState,
  state => state.totalElements,
);

export const getVesselFilter = createSelector(
  getVesselState,
  state => state.filter,
);

// eslint-disable-next-line @typescript-eslint/naming-convention
export function VesselReducer(state = initialState, action: Action): VesselState {
  switch (action.type) {
    case VesselActions.GET_VESSEL_LIST: {
      return {
        ...state,
        loading: true,
        error: false,
        page: action.payload.paging && action.payload.paging.page ? action.payload.paging.page : 0,
      };
    }

    case VesselActions.GET_ALL_VESSEL_LIST: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }

    case VesselActions.SET_VESSEL_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }

    case VesselActions.GET_VESSEL_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        totalElements: action.payload.totalElements,
        moreHits: action.payload.number + 1 < action.payload.totalPages,
        results: [...(state.page ? state.results : []), ...action.payload.content],
        firstVesselsCode: action.payload.content.length && action.payload.content[0].vesselCode,
        VesselList:action.payload.numberOfElements
      };
    }

    case VesselActions.GET_ALL_VESSEL_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        allVessels: [...action.payload.content].map((vessel: Vessel) => <IVesselSimple>({
          name: vessel.vesselName,
          code: vessel.vesselCode,
        })),
      };
    }

    case VesselActions.GET_VESSEL_LIST_ERROR: {
      return {
        ...state,
        error: true,
        loading: false,
      };
    }

    case VesselActions.SET_VESSEL_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }

    default: return state;
  }
}
