import { StatusName } from '@app/shared/components/status-indicator/status-indicator.constants';

const PORT_TYPE_DESTINATION = 'Destination';
const PORT_TYPE_ORIGIN = 'Origin';

const HOUR_LENGTH = 60 * 60 * 1000;
export const MOVING_TIME = 7 * 24 * HOUR_LENGTH;

export const claimedStatus = ['NA', 'UV', 'OA', 'AL', 'RL'];
export const standardStatus = ['UV', 'OA', 'AL', 'AE', 'RL'];
export const unloadNumerator = ['UV', 'OA', 'AL', 'RL'];
export const unloadDenominator = ['NA', 'UV', 'OA', 'AL', 'RL'];
export const loadNumerator = ['AE'];
export const loadDenominator = ['AE', 'I'];
export const unloadingStatus = ['UV', 'OA', 'AL', 'RL'];

export enum Status {
  INBOUND = 'NA',
  OUTBOUND = 'outbound',
  DOCKED = 'docked',
}

export const vesselNodeState = {
  [StatusName.INBOUND]: PORT_TYPE_DESTINATION,
  [StatusName.DOCKED]: PORT_TYPE_DESTINATION,
  [StatusName.WORKING]: PORT_TYPE_DESTINATION,
  [StatusName.OUTBOUND]: PORT_TYPE_ORIGIN,
};
export const unloadingFilter = {
  'filterKey': 'shipmentStatusCd',
  'filterValues': [],
};

export const vesselFilter = {
  'filterKey': 'vesselStatus',
  'filterValues': [],
};

export const requestParamsToSend = {
  'searchRequests': [
    {
      'fromDate': new Date(new Date(new Date().setDate(new Date().getDate() - 90))).toISOString().substr(0, 10) + 'T00:00:00',
      'toDate': new Date(new Date(new Date().setDate(new Date().getDate() + 90))).toISOString().substr(0, 10) + 'T23:59:59',
      'fetchNonOrRecentlyOutgatedContainers': true,
      'searchParameters': [
        {
          'key': 'byVesselTripId',
          'children': [
            {
              'key': 'byStatus',
              'children': [
                {
                  'key': 'byInboundDockedContainers',
                  'children': [],
                },
              ],
              'fetchSource': true,
            },
          ],
          'filters': [
            {
              'filterKey': 'importExport',
              'filterValues': ['I'],
            },
            {
              "filterKey": "portDestination",
              "filterValues": [
                  "2704","2709"
              ]
          },
          ],
          'firstSortPreference': {
            'orderBy': 'KEY',
            'orderByDirection': 'ASC',
          },
         'secondSortPreference': {
            'orderBy': 'COUNT',
            'orderByDirection': 'ASC',
          },
          'fetchSource': true,
          'fetchOnlyMyContainers': false,
        },
      ],
    },
  ],
};
